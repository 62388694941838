import request from './request'

export const records = (params) => {
  return request({
    url: 'fund_record/getFundRecords',
    params
  })
}

export const updateOperationStatus = (params) => {
  return request({
    url: 'fund_record/updateOperationStatus',
    method: 'POST',
    params
  })
}
